import type { Block } from 'types/api/block';

import { ADDRESS_PARAMS } from './addressParams';

export const BLOCK_HASH = '0x8fa7b9e5e5e79deeb62d608db22ba9a5cb45388c7ebb9223ae77331c6080dc70';

export const BLOCK: Block = {
  base_fee_per_gas: '14',
  burnt_fees: '92834504000000000',
  burnt_fees_percentage: 42.2,
  difficulty: '340282366920938463463374607431768211451',
  extra_data: 'TODO',
  gas_limit: '30000000',
  gas_target_percentage: 55.79,
  gas_used: '6631036',
  gas_used_percentage: 22.1,
  hash: BLOCK_HASH,
  height: 8988736,
  miner: ADDRESS_PARAMS,
  nonce: '0x0000000000000000',
  parent_hash: BLOCK_HASH,
  priority_fee: '19241635454943109',
  rewards: [
    {
      reward: '19241635454943109',
      type: 'Validator Reward',
    },
  ],
  size: 46406,
  state_root: 'TODO',
  timestamp: '2023-05-12T19:29:12.000000Z',
  total_difficulty: '10837812015930321201107455268036056402048391639',
  tx_count: 142,
  tx_fees: '19241635547777613',
  type: 'block',
  uncles_hashes: [],
  wo_header: {
    difficulty: 0,
    headerHash: '0x4181ebcde6cd5fd9b162d133fe0edbabd76deb264f76b15ec0f39144dc6a1757',
    location: '0x0',
    mixHash: '0x62df992379c915e2ced8f7ff2028df1a1ce99db8439d2af027176269a65f8f0f',
    nonce: 0,
    number: 0,
    parentHash: '0x44125f0eb36a9d942e0c23bb4e8117f7ba86a9537a69b59c0025986ed2b7500f',
    time: '2017-12-16T00:13:24.000000Z',
    txHash: '0x56e81f171bcc55a6ff8345e692c0f86e5b48e01b996cadc001622fb5e363b421',
  },
};
